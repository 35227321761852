import "core-js/modules/es.date.to-string"; /*
                                             * @Description:
                                             * @Version: 1.0
                                             * @Autor: silencc
                                             * @Date: 2020-11-13 14:58:21
                                             * @LastEditors: Please set LastEditors
                                             * @LastEditTime: 2021-12-09 17:44:38
                                             */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  // {
  //   label: '序号',
  //   type: 'index',
  //   width: 100
  // },
  {
    type: 'selection',
    width: 60 },

  {
    label: '项目编码',
    kname: 'code' },

  {
    label: '项目名称',
    kname: 'name' },

  {
    label: '一级分类',
    kname: 'firstCategoryName' },

  {
    label: '二级分类',
    kname: 'secCategoryName' },

  {
    label: '价格',
    kname: 'price' },

  {
    label: '销量',
    kname: 'salesVolume',
    sortable: true,
    prop: 'salesVolume' },

  {
    label: '创建时间',
    kname: 'createAt' },

  {
    label: '状态',
    kname: 'status',
    component: {
      name: 'c-switch',
      events: {
        change: ctx.changeStatus } } },



  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};



export var searchOption = function searchOption(ctx) {return [
  {
    kname: 'categoryList',
    label: '项目分类：',
    component: {
      name: 'selector',
      props: {
        type: 'categorysType',
        clearable: true,
        cascader: true,
        strictly: {
          checkStrictly: true } } } },




  {
    kname: 'name',
    label: '项目名称：',
    component: {
      name: 'el-input',
      props: {
        clearable: true,
        placeholder: '请输入项目名称' } } },



  {
    kname: 'startTime',
    label: '开始时间：',
    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        valueFormat: 'yyyy-MM-dd 00:00:00',
        pickerOptions: {
          disabledDate: function disabledDate(v) {
            if (v.valueOf() > new Date(new Date().toLocaleDateString()).valueOf()) {
              return true;
            }
            return false;
          } } } } },




  {
    kname: 'endTime',
    label: '结束时间：',
    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        valueFormat: 'yyyy-MM-dd 23:59:59',
        pickerOptions: {
          disabledDate: ctx.showEndTime } } } }];};